<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <price-x-logo/>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('messages.processing_login') }}
        </b-card-title>
      </b-card>
    </div>
  </div>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import {EventBus} from "@/plugins/event-bus";
import {mapActions} from "vuex";
import {BCard, BCardTitle, BLink} from "bootstrap-vue";
import PriceXLogo from '@core/layouts/components/Logo.vue'
export default {
  components: {BCard, BCardTitle, PriceXLogo, BLink},
  name: "SocialLogin",
  mounted(){
    if(this.$route.params.driver === 'facebook'){
      this.loginFacebook()
    }
    if(this.$route.params.driver === 'google'){
      this.loginGoogle()
    }
    if(this.$route.params.driver === 'apple'){
      this.loginApple()
    }
  },
  methods: {
    ...mapActions('user', ['fetch']),
    loginFacebook(){
      this.$http.get('/auth/social/facebook', {
        params: this.$route.query
      }).then(response => {
        if(response.data.redirect){
          this.$router.push({name: 'auth.login'})
        }
        useJwt.setToken(response.data.access_token)
        this.fetch().then(userResponse => {
          this.$ability.update(userResponse.data.ability)
          this.$nextTick(() => {
            EventBus.$emit('login')
            this.$router.push('/')
          })
        })
      })
    },
    loginGoogle(){
      this.$http.get('/auth/social/google', {
        params: this.$route.query
      }).then(response => {
        if(response.data.redirect){
          this.$router.push({name: 'auth.login'})
        }
        useJwt.setToken(response.data.access_token)
        this.fetch().then(userResponse => {
          this.$ability.update(userResponse.data.ability)
          this.$nextTick(() => {
            EventBus.$emit('login')
            this.$router.push('/')
          })
        })
      })
    },
    loginApple(){
      this.$http.get('/auth/social/apple', {
        params: this.$route.query
      }).then(response => {
        if(response.data.redirect){
          this.$router.push({name: 'auth.login'})
        }
        useJwt.setToken(response.data.access_token)
        this.fetch().then(userResponse => {
          this.$ability.update(userResponse.data.ability)
          this.$nextTick(() => {
            EventBus.$emit('login')
            this.$router.push('/')
          })
        })
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
